@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

@import 'styles/bootstrap.scss';
@import 'styles/helpers.scss';

:root {
  --bs-primary: white;
}

body {
  margin: 0;
  font-family: var(--theme-text-font), "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.btn,
.badge {
  font-family: var(--theme-title-font), "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

html {
  background-color: var(--bs-primary);
}

.theme-dark {
  background-color: black;
  color: white;
}

.theme-dark table {
  color: white;
}

.theme-dark .offcanvas {
  background-color: black;
  color: white;
}

.theme-dark .btn-close {
  color: white;
}

a {
  text-decoration: none;
  color: var(--bs-primary);
}

a:hover,
a:focus {
  text-decoration: none;
  color: var(--bs-primary-dark1);
}

// .form-control:focus {
//   color: #212529;
//   background-color: #fff;
//   border-color: var(--bs-primary);
//   outline: 0;
//   box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
// }

// .form-check-input:focus {
//   border-color: var(--bs-primary);
//   outline: 0;
//   box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
// }

// .form-select:focus {
//   border-color: var(--bs-primary);
//   outline: 0;
//   box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
// }

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  text-align: left;
}

pre {
  max-width: 100vw;
  // white-space: unset;
  // text-align: left;
}

.App-loader {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--bs-primary);
}

.App {
  text-align: center;
}

.App-header {
  background-color: var(--bs-primary);
  min-height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header h1 a {
  color: white;
  text-decoration: none;
}

.theme-dark .App-header h1,
.theme-dark .App-header h1 a {
  color: black;
}

.App-nav {
  background-color: #EEE;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.App-nav a {
  color: var(--bs-primary-dark1);
  text-decoration: none;
}

nav.hamburger {
  position: absolute;
  top: 10px;
}

nav.hamburger-left {
  left: 15px;
}

nav.hamburger-right {
  right: 15px;
}

nav.hamburger span,
th[data-field] {
  cursor: pointer;
}

.theme-dark nav.hamburger {
  color: black
}

.theme-light nav.hamburger {
  color: white
}

.App-section {
  /*background-color: #282c34;*/
  min-height: calc(100vh - 110px);
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  /*font-size: calc(10px + 2vmin);
  color: white;*/
  padding: 1em;
  max-width: 1400px;
  margin: auto;
}

@media (max-width: 575px) {
  .App-header {
    min-height: 80px;
  }

  .App-section {
    min-height: calc(100vh - 80px);
  }
}



h2 {
  // margin-top: .5em;
  // margin-bottom: 1em;
  font-size: 2em;
  color: var(--bs-primary);
  margin: 0 0 .2em;
  text-align: left;
}

.breadcrumb {
  margin: 0;
}

table {
  text-align: left;
  /*border-colo\r: #eee;*/
}

th {
  font-weight: 300;
}

th.active {
  font-weight: 500;
}

th.active i {
  font-weight: 300;
}

.toast-body {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.bg-primary {
  // --bs-bg-opacity: 1;
  background-color: var(--bs-primary) !important;
}

.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.theme-dark .btn-primary,
.theme-dark .btn-secondary,
.theme-dark .btn-primary:hover,
.theme-dark .btn-secondary:hover,
.theme-dark .btn-outline-secondary:hover {
  color: black;
}

a.text-theme {
  color: black;
  text-decoration: none;
}

.theme-dark a.text-theme {
  color: white;
}

a.text-theme:hover,
a.text-theme:active {
  text-decoration: underline;
}

.offcanvas {
  --bs-offcanvas-width: 380px;
}

.nowrap th,
.nowrap td,
.nowrap {
  white-space: nowrap;
}

.App-section-header {
  border-bottom: 1px solid var(--bs-primary);
  padding-bottom: 1em;
}

.picture-wrapper {
  width: 40px;
  height: 40px;
  background-color: white;
  line-height: 40px;
  border-radius: 2px;
}

@media (max-width: 575px) {
  .App-header {
    min-height: 80px;
  }

  .App-section {
    min-height: calc(100vh - 80px);
  }

  .offcanvas {
    --bs-offcanvas-width: 100vw;
  }
}

.border-top {
  --bs-border-color: var(--bs-primary);
}

.border-theme {
  border-color: var(--bs-primary) !important;
}

// Tabs
.tab-content {
  text-align: left;
}

// Forms
form {
  text-align: left;
}

.form-label {
  font-weight: 300;
}

.theme-dark .accordion {
  --bs-accordion-btn-color: var(--bs-primary);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27var%28--bs-accordion-btn-color%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%235c2e8a%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

// Accordions
.theme-dark .accordion-item {
  --bs-accordion-bg: #000;
  --bs-accordion-border-color: var(--bs-primary);
}

.theme-dark .accordion-button {
  --bs-accordion-btn-bg: #000;
  --bs-accordion-active-bg: #000;
  --bs-accordion-btn-color: var(--bs-primary);
  --bs-accordion-active-color: var(--bs-primary);
  box-shadow: none;
}

.theme-dark .accordion-button:not(.collapsed)::after {
  background-image: none;
  // transform: var(--bs-accordion-btn-icon-transform);
}

.accordion .table {
  margin-bottom: 0;
}

.nav-link {
  // font-size: .85em;
}

@media (max-width: 575px) {
  h2 {
    text-align: center;
  }

  .breadcrumb {
    justify-content: center;
  }

  .btn-group>.btn,
  .btn-group-vertical>.btn {
    font-size: .8rem;
  }

  .nav-pills {
    flex-direction: column;
  }
}

.progress-bar {
  background-color: var(--bs-primary);
}

.modal-header {
  background-color: var(--bs-primary);
  border-bottom-color: var(--bs-primary);
  color: black;
}

.subtask-list {
  border-top: 1px solid var(--bg-gray-dark);
}

.subtask-item {
  text-align: left;
  margin-bottom: 5px;
  ;
}

.text-blue {
  color: var(--bs-blue);
}

.text-purple {
  color: var(--bs-purple);
}

.text-green {
  color: var(--bs-green);
}

.text-orange {
  color: var(--bs-orange);
}

.text-yellow {
  color: var(--bs-yellow);
}

.text-red {
  color: var(--bs-red);
}

.text-pink {
  color: var(--bs-pink);
}

.text-indigo {
  color: var(--bs-indigo);
}


.subtask-list.collapsed {
  display: none !important;
}

.tooltip {
  --bs-tooltip-bg: #212529;
}

.dropdown-menu-dark {
  --bs-dropdown-bg: #212529;
}

.theme-light .tooltip {
  --bs-tooltip-bg: var(--bs-gray-300);
  --bs-tooltip-color: black;
}

.task {
  border: 1px solid var(--bs-primary);
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 15px;

  .task-item {
    height: 50px;
    line-height: 50px;
    text-align: left;
  }

  .subtask-info {
    // margin-top: -7px;
  }
}

.task-wrapper.archived .task {
  border-color: var(--bs-gray-600);
}

.task-wrapper.archived .task .task-title {
  text-decoration: line-through;
}

.subtask {
  border: 1px solid var(--bs-primary);
  border-radius: 5px;
  margin-left: 50px;
  margin-bottom: 15px;
  padding: 15px 20px;
  text-align: left;
  position: relative;

  &::before {
    content: " ";
    border-bottom: 1px solid var(--bs-gray-600);
    width: 25px;
    height: 75px;
    display: block;
    border-left: 1px solid var(--bs-gray-600);
    border-radius: 0 0 0 5px;
    position: absolute;
    right: calc(100% + 2px);
    bottom: 27px;
  }

  &:first-child:before {
    height: 43px;
  }

  .subtask-title {
    margin-bottom: 0;
  }
}

.subtask.sorting-helper::before,
.sorting .subtask::before {
  display: none;
}

.subtask.sorting-helper,
.sorting .subtask {
  border-style: dashed;
}

.task-wrapper.archived .subtask {
  border-color: var(--bs-gray-600) !important;
}

[contenteditable]:focus {
  outline: 1px solid var(--bs-primary);
}

::selection {
  // color: red;
  background: rgba(var(--bs-primary-rgb), .5);
}

.toast-header {
  background-color: var(--bs-primary);
  color: white;
}

.theme-dark .toast-header {
  color: black;
}

.modal-content {
  color: white;
}

// fieldset {
//   border: 1px groove #ddd !important;
//   padding: 0 1.4em 1.4em 1.4em !important;
//   margin: 0 0 1.5em 0 !important;
//   -webkit-box-shadow:  0px 0px 0px 0px #000;
//           box-shadow:  0px 0px 0px 0px #000;
// }

//   legend {
//       font-size: 1.2em !important;
//       font-weight: bold !important;
//       text-align: left !important;
//       width:auto;
//       padding:0 10px;
//       border-bottom:none;
//   }


.rbt-token {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  color: black;
  display: inline-flex;
  line-height: 1rem;
  margin: 1px 3px 2px 0;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary-dark1);
  --bs-btn-hover-border-color: var(--bs-primary-dark2);
  --bs-btn-focus-shadow-rgb: 125, 82, 168;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary-dark2);
  --bs-btn-active-border-color: var(--bs-primary-dark3);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
}

.btn-outline-primary {
  --bs-btn-color: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-focus-shadow-rgb: 102, 51, 153;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-primary);
  --bs-gradient: none;
}

.btn-icon.text-primary i {
  transition: color .2s;
}

.btn-icon.text-primary:hover i {
  color: var(--bs-primary-dark1);
}

.rbt-input-main {
  z-index: 0 !important;
}

.task-tags {
  margin-bottom: 15px;
}

.task-tag {
  font-size: .75em;
  margin-right: 7px;
  border: 1px solid var(--bs-gray-600);
  border-radius: 4px;
  padding: 1px 7px;
}

.dropdown-menu {
  padding: 0;
}

.dropdown-item.active,
.dropdown-item:active {
  color: black;
  background-color: var(--bs-primary);
}

.dropdown-item:first-child {
  border-radius: 0.375rem 0.375rem 0 0;
}

.dropdown-item:last-child {
  border-radius: 0 0 0.375rem 0.375rem;
}


.badge-primary {
  background-color: var(--bs-primary);
  color: white;
  font-size: 0.6em;
  vertical-align: top;
  margin-left: 10px;
  margin-top: 2px;
  font-weight: 400;
}

.theme-dark .badge-primary {
  color: black;
}

.dropdown .btn-outline-primary {
  border-radius: 0 .375rem .375rem 0 !important;
}

.toast-container {
  position: sticky !important;
  left: calc(100vw);
}

.theme-dark .btn-outline-primary:hover i.text-primary {
  color: black !important;
}

.theme-dark .progress {
  background-color: rgba(var(--bs-dark-rgb), 1) !important;
}

.theme-dark .form-check-input {
  background-color: var(--bs-dark);
}

.form-check-input:checked {
  background-color: var(--bs-primary);
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23212529%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}

.sorting .task {
  border-style: dashed;
}

.sorting .btn-icon,
.sorting .task-tags,
.sorting .subtask-info {
  display: none !important;
}

.dragging-helper-class .task {
  border-style: dashed;
}

.dragging-helper-class .btn-icon,
.dragging-helper-class .task-tags,
.dragging-helper-class .subtask-info {
  display: none !important;
}

.modal-footer {
  border-top-color: transparent;
}